'use client';

import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
} from 'react';
import { axiosInstance } from '../service/axiosConfig/axiosConfig';

export const BASE_URL = axiosInstance.defaults.baseURL+'/api';

interface Author {
    id: number;
    name: string;
    photo_link: string;
}

export interface ReviewInterface {
    author: Author;
    rating: number;
    created_at: string;
    text: string;
}

interface ReviewsContextType {
    handleGetReviews: (id: string, type: "recipient" | "sender") => Promise<any>;
}

const ReviewsContext = createContext<ReviewsContextType | undefined>(undefined);

export const ReviewsProvider = ({ children }: { children: ReactNode }) => {

    const handleGetReviews = async (id: string, type: "recipient" | "sender") => {
        try {
            const profile = await fetch(`${BASE_URL}/reviews/?type=${type}`, {
                headers: {
                    "tg-id": id
                }
            }).then(res => res.json());

            if (profile) {
                return profile;
            } else {
                console.error('Failed to fetch Profile');
            }
        } catch (error) {
            console.error('Failed to fetch Profile', error);
        }
    };

    return (
        <ReviewsContext.Provider
            value={{
                handleGetReviews,
            }}
        >
            {children}
        </ReviewsContext.Provider>
    );
};

export const useReviews = (): ReviewsContextType => {
    const context = useContext(ReviewsContext);
    if (context === undefined) {
        throw new Error('useReviews must be used within an ReviewsProvider');
    }
    return context;
};
