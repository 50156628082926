import React, { useEffect, useRef, useState } from 'react';


import { ReactComponent as UAFlag } from '../../assets/icons/countries/uaFlag.svg';
import { ReactComponent as UKFlag } from '../../assets/icons/countries/ukFlag.svg';
import { ReactComponent as GEFlag } from '../../assets/icons/countries/geFlag.svg';
import { ReactComponent as PLFlag } from '../../assets/icons/countries/plFlag.svg';
import { ReactComponent as USAFlag } from '../../assets/icons/countries/usaFlag.svg';

import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/clearIcon.svg';


import styles from './LocationSelect.module.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UnavailableCountryDialog from '../../components/UnavailableCountryDialog/UnavailableCountryDialog';

import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../../utils/storeActions';
import { axiosInstance } from '../../service/axiosConfig/axiosConfig';

import globalRequestStore from '../../store';
import { useQuery } from '../../utils/useQuery';
import { ROUTE_PATHS } from '../../const/routes';


const CustomLocationSelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const id = query?.get('id');


  const [searchParams] = useSearchParams();
  const tgId = getHashedTgIdFromLocalStorage() || searchParams.get('hashed-td-id') as string
  const {t} = useTranslation();


  const ref = useRef(null);

  const [isOpen, setIsOpen] = React.useState(false);


  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const handleFetchResults = async (search: string) => {

    if (!search) return null
    const url = axiosInstance.defaults.baseURL+`/api/search/autocomplete/?search=${encodeURIComponent(search)}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'tg-id': tgId,
        }
      });

      if (!response.ok) {
        globalRequestStore.setError('Network response was not ok')
      }

      const data = await response.json();

      if (data?.predictions?.length) {
        if (data.predictions.length > 0) {

          const filtered = data.predictions.map((prediction: any) => ({
            title: prediction.description,
            placeId: prediction.place_id,
            blocked: prediction.blocked
          }))
          setSearchResults(filtered);
        } else {
          setIsOpen(true);
        }
      }
    } catch (e) {
    }
  }

  const handleSearchSet = (event: any) => {
    setSearch(event.target.value)
    event.target.value.length > 2 ? handleFetchResults(event.target.value) : setSearchResults([]);
  };

  const handleSetSearchForCountry = async (country: string) => {
    if (!country) return null;
    try {
      const data = await axiosInstance.get(`api/search/autocomplete?search=${country}`, {
        headers: {
          'tg-id': tgId,
        },
      }).then(res => res.data);

      // const filteredResults = recentSearchResults.filter(
      //   (result) => result.placeId !== data.predictions[0].place_id
      // );
      //
      // await postRecentSearchResult(
      //  tgId,
      //   [
      //     {title: country, placeId: data.predictions[0].place_id},
      //     ...filteredResults.slice(0, 4)
      //   ],
      //   destination + '_' + (!!location?.state?.from ? 'send' : 'deliver')
      // );


      handleDestinationSelect({
        title: country,
        placeId: data?.predictions[0]?.place_id,
        blocked: false
      });
    } catch (e: any) {
      globalRequestStore.setError(e?.message);
    }
  };

  const handleDestinationSelect = (value: { title: string, placeId: string, blocked: boolean }) => {
    if (value.blocked) {
      setIsOpen(true)
      return
    }
    navigate(id != '0' ? ROUTE_PATHS.EDIT_INFO_PAGE + '?id=' + id : ROUTE_PATHS.CHECK_YOUR_DELIVER + '?id=' + id, {
      replace: true,
      state: {
        ...location.state,
        newDestination: value
      }
    })
    setTimeout(()=>navigate(-1),100)
  }

  useEffect(() => {
    if (tgId) {
      // handleFetchRecentResults();
    }
  }, [searchParams])


  return (
    <div className={styles.pageWrapper}>
      <div className={styles.searchBar}>
        {/*// @ts-ignore*/}
        <SearchIcon onClick={() => ref && ref?.current?.focus()} className={styles.searchIcon}/>
        <input ref={ref} className={styles.searchInput} onChange={handleSearchSet}
               placeholder={t('location_select_page.search_placeholder')} value={search} type="text"/>
        {!!search && (
          <ClearIcon onClick={() => setSearch('')} className={styles.clearIcon}/>
        )}
      </div>
      {/*{!search && (*/}
      {/*  <>*/}

      {/*    <h2 className={styles.popularDestinations}>{t('location_select_page.popular_destinations')}</h2>*/}
      {/*    <div className={styles.countriesPreSelect}>*/}
      {/*      <div onClick={() => handleSetSearchForCountry(t('location_select_page.ukraine'))}*/}
      {/*           className={styles.countryItem}>*/}
      {/*        <div className={styles.avatar}>*/}
      {/*          <UAFlag/>*/}
      {/*        </div>*/}
      {/*        <p>{t('location_select_page.ukraine')}</p>*/}
      {/*      </div>*/}
      {/*      <div onClick={() => handleSetSearchForCountry(t('location_select_page.germany'))}*/}
      {/*           className={styles.countryItem}>*/}
      {/*        <div className={styles.avatar}>*/}
      {/*          <GEFlag/>*/}
      {/*        </div>*/}
      {/*        <p>{t('location_select_page.germany')}</p>*/}
      {/*      </div>*/}
      {/*      <div onClick={() => handleSetSearchForCountry(t('location_select_page.usa'))}*/}
      {/*           className={styles.countryItem}>*/}
      {/*        <div className={styles.avatar}>*/}
      {/*          <USAFlag/>*/}
      {/*        </div>*/}
      {/*        <p>{t('location_select_page.usa')}</p>*/}
      {/*      </div>*/}
      {/*      <div onClick={() => handleSetSearchForCountry(t('location_select_page.indonesia'))}*/}
      {/*           className={styles.countryItem}>*/}
      {/*        <div className={styles.avatar}>*/}
      {/*          <PLFlag/>*/}
      {/*        </div>*/}
      {/*        <p>{t('location_select_page.indonesia')}</p>*/}
      {/*      </div>*/}
      {/*      <div onClick={() => handleSetSearchForCountry(t('location_select_page.uk'))}*/}
      {/*           className={styles.countryItem}>*/}
      {/*        <div className={styles.avatar}>*/}
      {/*          <UKFlag/>*/}
      {/*        </div>*/}
      {/*        <p>{t('location_select_page.uk')}</p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}

      {/*{recentSearchResults.length > 0 && !search && (*/}
      {/*  <div className={styles.recentSearchBlock}>*/}
      {/*    <h2 className={styles.subTitle}>{t('recent_search')}</h2>*/}
      {/*    <div className={styles.searchItems}>*/}
      {/*      {recentSearchResults.slice(0, 5).map((result) => (*/}
      {/*        <div*/}
      {/*          onClick={() => handleDestinationSelect({*/}
      {/*            title: typeof result === 'string' ? result : result.title,*/}
      {/*            placeId: typeof result === 'string' ? result : result.placeId,*/}
      {/*            blocked: result.blocked*/}
      {/*          })}*/}
      {/*          className={styles.result}>*/}
      {/*          {typeof result === 'string' ? result : result.title}*/}
      {/*        </div>*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      {!!searchResults.length && (
        <div className={styles.recentSearchBlock}>
          <h2 className={styles.subTitle}>{t('location_select_page.countries_and_cities')}</h2>
          <div className={styles.searchItems}>
            {searchResults.map((result) => {

              return (
                <div onClick={() => handleDestinationSelect(result)} className={styles.result}>
                  {result.title}
                </div>
              )
            })}
          </div>
        </div>
      )}

      {isOpen && <UnavailableCountryDialog onSubmit={() => setIsOpen(false)}/>}


    </div>
  );
};

export default CustomLocationSelect;
