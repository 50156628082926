import React, { FC, useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { useSendParcel } from '../../context/SendParcelContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import styles from './DateRangeSelect.module.css'
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button/Button';
import { useDeliverParcel } from '../../context/DeliverParcelContext';
import { ROUTE_PATHS } from '../../const/routes';
import moment from 'moment';
import i18n from '../../i18n';
import { useQuery } from '../../utils/useQuery';

const GlobalStyle = createGlobalStyle`
  .rdrDefinedRangesWrapper,
  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthName {
    text-align: center;
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    color: #000000;
    padding: 0;
    margin-bottom: 8px;
  }

  .rdrMonth {
    padding-left: 0;
    padding-right: 0;
  }

  .rdrWeekDays .rdrWeekDay {
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

  .rdrDateRangePickerWrapper {
    height: calc(100vh - 152.5px);
    overflow-y: auto;
  }

  //.rdrDayToday span {
  //  color: white!important;
  //}
  //
  //.rdrDayToday .rdrDayNumber span {
  //    color: black!important;
  //    font-size: 13px;
  //    font-weight: 400;
  //    line-height: 16px;
  //}
  //
  //.rdrDayToday .rdrDayNumber span::after {
  //    background: #007AFF!important;
  //}

  .rdrStartEdge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #007AFF !important;
  }

  .rdrInRange {
    color: #DDE8FF !important;
  }

  .rdrDayNumber {
    font-weight: 590;
  }

  .rdrInRange ~ .rdrDayNumber span {
    color: #000000 !important;
  }

  .rdrEndEdge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #007AFF !important;
  }

  .rdrDayEndPreview,
  .rdrDayStartPreview {
    border: none;
    color: #007AFF !important;
  }

  .rdrMonths {
    flex-direction: column;
  }
`;

const CustomDateRangeSelect: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const id = query?.get('id');
  const {dateRange: oldDateRange} = location.state
  const {t} = useTranslation();

  const [searchParams] = useSearchParams();

  const [dateRange, setDateRange] = useState<any>([{
    startDate: oldDateRange.startDate,
    endDate: oldDateRange.endDate,
    key: 'selection'
  }]);

  const handleDateRangeChange = (data: [{ startDate: Date; endDate: Date; key: string }] | undefined) => {
    setDateRange(data);
  };

  const handleReset = () => {
    setDateRange([{startDate: new Date(), endDate: new Date(), key: 'selection'}])
    // location.pathname.includes(ROUTE_PATHS.PARCEL_SEND) ? sendDateRangeChange(undefined) : deliverDateRangeChange(undefined);
  }

  const handleSubmit = () => {
    navigate(id != '0' ? ROUTE_PATHS.EDIT_INFO_PAGE + '?id=' + id : ROUTE_PATHS.CHECK_YOUR_DELIVER + '?id=' + id, {
      replace: true,
      state: {
        ...location.state,
        newDateRange: {startDate: dateRange[0].startDate, endDate: dateRange[0].endDate}
      }
    })
    setTimeout(()=>navigate(-1),100)
  }
  moment.locale(i18n.language);

  return (
    <div className={styles.calendarWrapper}>
      <GlobalStyle/>
      <DateRangePicker
        weekStartsOn={1}
        minDate={new Date()}
        ranges={dateRange}
        // @ts-ignore
        onChange={(item) => {
          // @ts-ignore
          handleDateRangeChange([item.selection])
        }}
        months={2}
        direction="vertical"
      />
      <div className={styles.footer}>

        <div>
          {moment(dateRange[0]?.startDate).format('D MMMM')} - {moment(dateRange[0]?.endDate).format('D MMMM')}
        </div>

        <div className={styles.buttons}>
          <Button text={t('date_range_select.title')} size={'large'} color={'fill'} onClick={handleSubmit}/>
          <Button text={t('date_range_select.reset')} size={'large'} color={'bezeled'} onClick={handleReset}/>

        </div>
      </div>
    </div>
  );
};

export default CustomDateRangeSelect;
