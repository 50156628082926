'use client';

import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
} from 'react';
import {axiosInstance} from "../service/axiosConfig/axiosConfig";
import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../utils/storeActions';

export const BASE_URL = axiosInstance.defaults.baseURL+'/api';

interface RecentSearchContextType {
    handleGetRecentSearch: (id:string, type: string) => Promise<any>;
    postRecentSearchResult: (id:string, data: any[], type: string) => Promise<any>;
}

const RecentSearchContext = createContext<RecentSearchContextType | undefined>(undefined);

export const RecentSearchProvider = ({ children }: { children: ReactNode }) => {

    const handleGetRecentSearch = async (id: string, type: string) => {
        const tgId = getHashedTgIdFromLocalStorage()
        try {
            const profile = await fetch(`${BASE_URL}/users/get-autofill/?type=${type}`, {
                headers: {
                    "tg-id": String(tgId)
                }
            }).then(res => res.json());

            if (profile) {
                return profile;
            } else {
                console.error('Failed to fetch Profile');
            }
        } catch (error) {
            console.error('Failed to fetch Profile', error);
        }
    };

    const postRecentSearchResult = async (id:string, data: any[], type: string) => {

        const tgId = getHashedTgIdFromLocalStorage()
        const results = await axiosInstance.post(`${BASE_URL}/users/set-autofill/?type=${type}`, data, {
            headers: {
                "tg-id": String(tgId)
            }
        })

        if (results) {
            return results;
        }
    }

    return (
        <RecentSearchContext.Provider
            value={{
                handleGetRecentSearch,
                postRecentSearchResult
            }}
        >
            {children}
        </RecentSearchContext.Provider>
    );
};

export const useRecentSearch = (): RecentSearchContextType => {
    const context = useContext(RecentSearchContext);
    if (context === undefined) {
        throw new Error('useRecentSearch must be used within an RecentSearchProvider');
    }
    return context;
};
