'use client';

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react';
import { saveProfileToLocalStorage } from '../utils/storeActions';
import globalRequestStore from '../store';
import { axiosInstance } from '../service/axiosConfig/axiosConfig';

export const BASE_URL = axiosInstance.defaults.baseURL+'/api';

export interface UpdateProfileDto {
  username: string;
  name: string;
  sex: string;
  bio: string;
  birthdate: string;
  social_link: string;
  photo_link: string;
}

export interface ProfileInterface extends UpdateProfileDto {
  id: string;
  tg_id: string;
  rating: string;
  hashed_tg_id: string;
}

interface ProfileContextType {
  profileData: ProfileInterface | null;
  handleGetProfileData: (id: string) => Promise<any>;
  handleUpdateProfile: (id: string, data: any) => Promise<void>;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider = ({children}: { children: ReactNode }) => {
  const [profileData, setProfileData] = useState<ProfileInterface | null>(null);

  const handleGetProfileData = async (id: string) => {
    try {
      globalRequestStore.startLoading();
      const profile = await fetch(`${BASE_URL}/users/`, {
        headers: {
          'tg-id': id,
          // "Content-Type": "application/json"
        },
        method: 'GET',
      }).then(res => res.json()).catch(console.log);
      globalRequestStore.finishLoading();
      if (profile) {
        saveProfileToLocalStorage({...profile})
        setProfileData(profile[0]);
        return profile;
      } else {
        console.error('Failed to fetch Profile');
      }

    } catch (error: any) {
      globalRequestStore.setError(error.message)
    }
  };

  const handleClearProfileData = () => {
    setProfileData(null);
  };

  const handleUpdateProfile = async (id: string, data: any) => {
    try {
      await fetch(`${BASE_URL}/users/`, {
        headers: {
          'tg-id': id
        },
        method: 'PUT',
      });
      const updatedProfile = await handleGetProfileData(id);
      setProfileData(updatedProfile);
    } catch (error) {
      console.error('Failed to update profile', error);
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        profileData,
        handleGetProfileData,
        handleUpdateProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within an ProfileProvider');
  }
  return context;
};
